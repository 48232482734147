<template>
  <div id="app">
    <div class="layout-container">
      <!-- 侧边栏 -->
      <keep-alive>
        <Sidebar></Sidebar>
      </keep-alive>
      <!-- 主内容区域 -->
      <main>
        <router-view> </router-view>
      </main>
    </div>
  </div>
</template>

<script>
import Sidebar from "./layout/sideBar/sidbar.vue";
export default {
  components: {
    Sidebar,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
ul {
  padding: 0;
}
ul li {
  list-style: none;
}

/* .el-popover {
  background-color: #4981ff !important;
  padding: 5px 0 !important;
  padding-left: 10px !important;

  color: #fff !important;
  border-radius: 10px !important;
  font-weight: bold !important;
  box-shadow: 0 0 5px 0 rgba(90, 39, 39, 0.5) !important;
  border: 3px solid #fff !important;
  min-width: 120px !important;
}
.el-popper .popper__arrow::after {
  border-color: transparent #4981ff transparent transparent !important;
} */
.layout-container {
  display: flex;
}
aside {
  width: 200px;
  background-color: #f0f0f0;
}
main {
  flex: 1;
  margin-left: 200px;
}
@media (max-width: 768px) {
  main {
    margin: 0;
  }
  .layout-container {
    display: block;
  }
}
</style>
