import Vue from 'vue'
import VueRouter from 'vue-router'
// import layout from '../layout/index.vue'
import Dashboard from '../views/dashboard/index.vue'
import Product from '../views/product/index.vue'
import userInfo from '../views/userInfo/index.vue'
import about from '../views/about/index.vue'
import agreement from '../views/agreement/index.vue'
import privacy from '../views/privacy/index.vue'
import copyright from '../views/copyright/index.vue'
import contact from '../views/contact/index.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: '导航检索网',
      scroll: ''
    }
  },
  {
    path: '/product',
    name: 'Product',
    component: Product
  },
  {
    path: '/userinfo',
    name: 'userinfo',
    component: userInfo,
    meta: {
      title: '个人中心',
      scroll: ''
    }
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: agreement
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacy
  },
  {
    path: '/copyright',
    name: 'copyright',
    component: copyright
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    localStorage.setItem('fromSecondLevelToHome', 'true')
  }
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
    let meta = document.querySelector('meta[name="description"]')
    if (!meta) {
      meta = document.createElement('meta')
      meta.setAttribute('name', 'description')
      document.head.appendChild(meta)
    }
    meta.setAttribute('content', to.meta.description)
  }
  next()
})
export default router
