<template>
  <div class="foot flex flex_center flex_mid">
    <div style="display: flex">
      <div
        :class="isMobile ? 'mobileContent' : 'bottom_content'"
        @click="goAbout"
      >
        关于我们
      </div>
      <div
        :class="isMobile ? 'mobileContent' : 'bottom_content'"
        @click="goContact"
      >
        联系我们
      </div>
      <div
        :class="isMobile ? 'mobileContent' : 'bottom_content'"
        @click="handleReportDialog"
      >
        用户反馈
      </div>
      <div
        :class="isMobile ? 'mobileContent' : 'bottom_content'"
        @click="goAgreement"
      >
        用户协议
      </div>
      <div
        :class="isMobile ? 'mobileContent' : 'bottom_content'"
        @click="goPrivacy"
      >
        隐私政策
      </div>
      <div
        :class="isMobile ? 'mobileContent' : 'bottom_content'"
        @click="goCopyright"
      >
        版权投诉
      </div>
      <div
        :class="isMobile ? 'mobileContent' : 'bottom_content'"
        @click="goBei"
      >
        粤ICP备2023008132号-2
      </div>
    </div>
    <el-dialog :visible.sync="reportDialog" :show-close="false">
      <div class="reportdialog">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="反馈内容:">
            <el-input type="textarea" v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="姓名:">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="电话:">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="邮箱:">
            <el-input v-model="form.mailbox"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSub">提交</el-button>
            <el-button @click="handleCancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { feedback } from "@/api/index.js";
export default {
  name: "LayoutFoot",
  data() {
    return {
      reportDialog: false,
      form: {
        name: "",
        phone: "",
        mailbox: "",
        title: "",
      },
    };
  },
  props: ["isMobile"],
  methods: {
    goAbout() {
      this.$router.push({ path: "about" });
    },
    goAgreement() {
      this.$router.push({ path: "agreement" });
    },
    goPrivacy() {
      this.$router.push({ path: "privacy" });
    },
    goCopyright() {
      this.$router.push({ path: "copyright" });
    },
    goBei() {
      window.open("https://beian.miit.gov.cn/");
    },
    goContact() {
      this.$router.push({ path: "contact" });
    },
    handleReportDialog() {
      this.reportDialog = true;
    },
    handleCancel() {
      this.reportDialog = false;
      this.form = {
        name: "",
        phone: "",
        mailbox: "",
        title: "",
      };
    },
    async handleSub() {
      if (!this.form.title) {
        this.$message.error("请填写举报内容");
      } else {
        if (localStorage.getItem("token")) {
          const res = await feedback(this.form);
          if (res.code === 1) {
            this.$message.success("提交成功!");
            this.handleCancel();
          }
        } else {
          this.loginDialog = true;
        }
      }
    },
  },
};
</script>
<style scoped>
.foot {
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: center;
  background: #000000;
  color: #fff;
  width: 100%;
  height: 60px;
}

.flex {
  display: flex;
}

.flex_center {
  justify-content: center;
}

.flex_mid {
  align-items: center;
}

.flex_bt {
  justify-content: space-between;
}

.bottom_content {
  font-size: 14px;
  color: #666;
  margin-left: 20px;
  cursor: pointer;
}

.mobileContent {
  font-size: 10px;
  color: #666;
  margin-left: 20px;
  cursor: pointer;
}

.reportdialog {
  width: 500px;
  height: 270px;
  background-color: #fff;
  margin-left: -280px;
  padding: 100px 50px;
  padding-top: 80px;
  border-radius: 10px;
}
</style>
