<template>
  <div class="userinfo">
    <div class="userinfo_top">
      当前位置：首页<i class="el-icon-arrow-right"></i>个人中心
    </div>
    <el-card style="margin-top: 60px">
      <div style="margin: 10px 0">
        <span class="line"></span>
        <span
          style="
            display: inline-block;
            width: 100px;
            margin-bottom: 10px;
            font-family: 'ma';
          "
          >个人信息</span
        >
      </div>

      <div class="flex flex-mid">
        <img style="width: 85px; height: 85px" :src="userInfo.avatar" alt="" />
        <div class="txtbox">
          <div class="flex info" style="justify-content: space-between">
            <div class="infotxt">昵称：{{ userInfo.nickname }}</div>
            <div class="infotxt">
              性别：
              <el-select
                size="small"
                style="width: 100px"
                v-model="userInfo.gender"
                @change="genderChange"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="infotxt">注册时间：{{ userInfo.jointime }}</div>
          </div>
          <div class="flex info" style="justify-content: space-between">
            <div class="infotxt">编 号：{{ userInfo.id }}</div>
            <div class="infotxt">
              <span v-if="userInfo.mobile"
                >手机号码：{{ userInfo.mobile }}</span
              >
              <div @click="bindMobile" v-else>
                <span>手机号码：</span>
                <span class="bindMobile">绑定手机号</span>
              </div>
            </div>
            <div class="infotxt">微信绑定：已绑定</div>
          </div>
        </div>
      </div>
    </el-card>

    <el-card style="margin-top: 10px">
      <el-tabs v-model="activeName" @tab-click="tabchange">
        <el-tab-pane label="订单中心" name="first">
          <div class="userinfo_info_right">
            <div class="order_total" style="margin-left: 37px">
              <div class="order_total_top">订单总个数</div>
              <div class="order_total_bottom">
                <span class="order_total_left">{{ count }}</span>
                <span class="order_total_right">个</span>
              </div>
            </div>
            <div class="order_total" style="margin-left: 37px">
              <div class="order_total_top">已完成服务</div>
              <div class="order_total_bottom">
                <span class="order_total_left">{{ status }}</span>
                <span class="order_total_right">次</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div style="min-width: 16%; text-align: center">订单编号</div>
            <div style="min-width: 16%; text-align: center">服务项目</div>
            <div style="min-width: 16%; text-align: center">支付时间</div>
            <div style="min-width: 16%; text-align: center">支付金额</div>
            <div style="min-width: 16%; text-align: center">订单状态</div>
            <div style="min-width: 16%; text-align: center">订单操作</div>
          </div>
          <div class="order">
            <div
              class="order_item"
              v-for="(item, index) in orderList"
              :key="index"
            >
              <div class="order_item_column">{{ item.order_id }}</div>
              <div class="order_item_column">{{ item.name }}</div>
              <div class="order_item_column">{{ item.add_time }}</div>
              <div class="order_item_column">{{ item.pay_price }}</div>
              <div class="order_item_column" v-if="item.status == '待安装'">
                <el-popover
                  width="280"
                  trigger="hover"
                  popper-class="custom-popover"
                  content="已成功分配技术人员，工作时段：9点~22点"
                >
                  <div
                    slot="reference"
                    @click="handleServer"
                    style="color: #145dff; cursor: pointer"
                  >
                    {{ item.status }}
                  </div>
                </el-popover>
              </div>
              <div
                class="order_item_column"
                v-else
                style="color: #145dff; cursor: pointer"
              >
                {{ item.status }}
              </div>
              <!-- <div class="order_item_column">{{ item.status }}</div> -->

              <div class="order_item_column">
                <div><img src="@/assets/客服图标.png" /></div>
                <div style="margin-left: 10px" @click="handleServer">
                  联系客服,立即对接
                </div>
              </div>
            </div>
            <div class="pagination">
              <el-pagination
                layout="prev, pager, next"
                :total="count"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="我的反馈" name="third">
          <div class="flex" style="justify-content: center; width: 100%">
            <el-form
              label-position="right"
              label-width="120px"
              style="width: 50%"
              ref="newform"
              :model="newForm"
              :rules="rules"
            >
              <el-form-item label="反馈内容:" prop="title">
                <el-input type="textarea" v-model="newForm.title"></el-input>
              </el-form-item>
              <el-form-item label="姓名:">
                <el-input v-model="newForm.name"></el-input>
              </el-form-item>
              <el-form-item label="电话:" prop="phone">
                <el-input v-model="newForm.phone"></el-input>
              </el-form-item>
              <el-form-item label="邮箱:" prop="mailbox">
                <el-input v-model="newForm.mailbox"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="flex" style="justify-content: center; width: 100%">
            <el-button style="width: 300px" @click="handleSub" type="primary"
              >提交申请</el-button
            >
          </div>
        </el-tab-pane>
        <el-tab-pane label="反馈内容" name="second">
          <div class="column">
            <div style="min-width: 25%; text-align: center">反馈内容</div>
            <div style="min-width: 16%; text-align: center">姓名</div>
            <div style="min-width: 16%; text-align: center">手机号</div>
            <div style="min-width: 16%; text-align: center">邮箱</div>
          </div>
          <div class="order" style="position: relative">
            <div
              class="order_item"
              v-for="(item, index) in feedList"
              :key="index"
            >
              <div class="order_item_column" style="width: 25%">
                {{ item.title }}
              </div>
              <div class="order_item_column">{{ item.name }}</div>
              <div class="order_item_column">{{ item.phone }}</div>
              <div class="order_item_column">{{ item.mailbox }}</div>
            </div>
            <div class="feedpagination">
              <el-pagination
                layout="prev, pager, next"
                :total="feedcount"
                @current-change="feedCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <div class="mobiledialog">
      <el-dialog
        title="绑定手机号"
        custom-class="mobiledialog"
        :visible.sync="mobileVisible"
        width="400px"
      >
        <!-- <div style="display: flex; align-items: center">
          <span>手机号：</span>
          <el-input v-model="newMobile" style="width: 200px"></el-input>
        </div> -->
        <el-form
          label-position="right"
          label-width="90px"
          style="width: 50%"
          ref="newmobileform"
          :model="newmobileForm"
          :rules="mobileRules"
        >
          <el-form-item label="手机号:" prop="newMobile">
            <el-input
              style="width: 180px"
              v-model="newmobileForm.newMobile"
            ></el-input>
          </el-form-item>
        </el-form>
        <span
          slot="footer"
          style="display: flex; justify-content: center"
          class="dialog-footer"
        >
          <el-button size="small" @click="mobileVisible = false"
            >取 消</el-button
          >
          <el-button size="small" type="primary" @click="bindConfirm"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>

    <!-- <div class="userinfo_info">
      <div class="userinfo_info_left">
        <div class="userinfo_info_left">
          <img src="@/assets/矩形.png" />
        </div>
        <div class="userinfo_info_left_right">
          <div class="userinfo_info_left_top">{{ nickname }}</div>
          <div class="userinfo_info_left_bottom">ID:{{ id }}</div>
        </div>
      </div>
      <div class="userinfo_info_right">
        <div class="order_total" style="margin-left: 37px">
          <div class="order_total_top">订单总个数</div>
          <div class="order_total_bottom">
            <span class="order_total_left">{{ count }}</span>
            <span class="order_total_right">个</span>
          </div>
        </div>
        <div class="order_total" style="margin-left: 37px">
          <div class="order_total_top">已完成服务</div>
          <div class="order_total_bottom">
            <span class="order_total_left">{{ status }}</span>
            <span class="order_total_right">次</span>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div style="min-width: 16%; text-align: center">订单编号</div>
      <div style="min-width: 16%; text-align: center">服务项目</div>
      <div style="min-width: 16%; text-align: center">订单时间</div>
      <div style="min-width: 16%; text-align: center">支付金额</div>
      <div style="min-width: 16%; text-align: center">订单状态</div>
      <div style="min-width: 16%; text-align: center">订单操作</div>
    </div>
    <div class="order">
      <div class="order_item" v-for="(item, index) in orderList" :key="index">
        <div class="order_item_column">{{ item.order_id }}</div>
        <div class="order_item_column">{{ item.name }}</div>
        <div class="order_item_column">{{ item.add_time }}</div>
        <div class="order_item_column">{{ item.pay_price }}</div>
        <div class="order_item_column">{{ item.status }}</div>
        <div class="order_item_column">
          <div><img src="@/assets/客服图标.png" /></div>
          <div style="margin-left: 10px" @click="handleServer">
            联系客服,立即对接
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          layout="prev, pager, next"
          :total="count"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div> -->
    <ServerDialog v-if="serverDialog" @close="handleServerClose"></ServerDialog>
    <LoginDialog
      v-if="loginDialog"
      @close="handleclose"
      @avatar="handleAvatar"
    ></LoginDialog>
  </div>
</template>

<script>
import {
  getOrderListAPI,
  feedback,
  mobile,
  feedbackList,
  editUseinfo,
  getUserInfoAPI,
} from "@/api/index.js";
export default {
  data() {
    return {
      newmobileForm: {
        newMobile: "",
      },
      test: 1,
      options: [
        { value: 0, label: "女" },
        { value: 1, label: "男" },
      ],
      feedcount: 0,
      userInfo: null,
      nickname: "",
      id: "",
      orderList: [],
      count: 0,
      status: "",
      serverDialog: false,
      form: {
        page: 1,
        pay_status: 1,
      },
      feedform: {
        page: 1,
      },
      loginDialog: false,
      activeName: "first",
      newForm: {
        title: "",
        name: "",
        phone: "",
        mailbox: "",
      },
      mobileVisible: false,
      newMobile: "",
      feedList: [],
      mobileRules: {
        newMobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            pattern: /^1[0-9]{10}$/,
            message: "请正确输入11位手机号码",
            trigger: "blur",
          },
        ],
      },
      rules: {
        title: [
          {
            required: true,
            message: "请输入反馈内容",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            pattern: /^1[0-9]{10}$/,
            message: "请正确输入11位手机号码",
            trigger: "blur",
          },
        ],
        mailbox: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
          {
            validator: function (rule, value, callback) {
              if (
                /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(
                  value
                ) === false
              ) {
                callback(new Error("邮箱格式错误"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.nickname = localStorage.getItem("nickname");
    this.id = localStorage.getItem("id");
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.getOrderList();
    this.getFeedbackList();
  },
  methods: {
    tabchange() {
      this.newForm.title = "";
      this.newForm.name = "";
      this.newForm.phone = "";
      this.newForm.mailbox = "";
      this.feedform.page = 1;
      this.feedcount = 0;
      this.getFeedbackList();
    },
    getFeedbackList() {
      feedbackList(this.feedform).then((res) => {
        console.log("===++", res);
        this.feedList = res.data.list;
        this.feedcount = Number(res.data.count);
      });
    },
    bindConfirm() {
      this.$refs.newmobileform.validate((valid) => {
        if (valid) {
          mobile({ mobile: this.newmobileForm.newMobile }).then((res) => {
            this.userInfo.mobile = this.newmobileForm.newMobile;
            this.$message.success(res.msg);
            this.mobileVisible = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    bindMobile() {
      this.mobileVisible = true;
      // mobile(this.newMobile).then((res) => {});
    },
    async handleSub() {
      this.$refs.newform.validate((valid) => {
        if (valid) {
          if (localStorage.getItem("token")) {
            const res = feedback(this.newForm);
            if (res.code === 1) {
              this.$message.success("提交成功!");
              // this.handleCancel();
            }
          } else {
            this.loginDialog = true;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleAvatar(avatar) {
      this.avatar = avatar;
    },
    async getOrderList() {
      try {
        const res = await getOrderListAPI(this.form);
        this.orderList = res.data.list;
        this.count = Number(res.data.count);
        this.status = res.data.status;
      } catch (error) {
        if (error.response.data.code === 401) {
          this.$message.error("登录状态失效,请重新登录");
          this.loginDialog = true;
        }
      }
    },
    handleServer() {
      this.serverDialog = true;
    },
    handleServerClose() {
      this.serverDialog = false;
    },
    handleCurrentChange(current) {
      this.form.page = current;
      this.getOrderList();
    },
    feedCurrentChange(current) {
      this.feedform.page = current;
      this.getFeedbackList();
    },
    handleclose(mode) {
      this.getOrderList();
      this.loginDialog = mode;
    },
    genderChange() {
      editUseinfo({ gender: this.userInfo.gender }).then((res) => {
        this.$message.success("操作成功");
        getUserInfoAPI().then((res) => {
          this.userInfo.gender = res.data.gender;
        });
      });
    },
  },
};
</script>
<style >
.mobiledialog .el-dialog {
  width: 400px !important;
  height: 250px !important;
}

.custom-popover .popper__arrow,
.custom-popover .popper__arrow::after {
  border-color: #ff0000 transparent transparent transparent; /* 红色边框 */
}
</style>
<style scoped>
div {
  box-sizing: border-box;
}
.userinfo {
  background-color: #fff;
  padding: 20px;
  margin-left: 20px;
  border-left: 1px solid #ccc;
}
.userinfo_top {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  padding: 20px 0;
  z-index: 9;
  color: #9fa7af;
}
.content_top {
  width: 100%;
  font-size: 12px;
  color: #9fa7af;
  font-family: fangyuan;
}
.userinfo_info {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.userinfo_info_left {
  display: flex;
  align-items: center;
  font-family: fangyuan;
  font-weight: bold;
}
.userinfo_info_left_right {
  margin-left: 20px;
  display: flex;
  font-size: 40px;
  flex-direction: column;
  justify-content: center;
}
.userinfo_info_left_bottom {
  font-size: 25px;
  margin-top: 20px;
}
.userinfo_info_right {
  display: flex;
  /* justify-content: flex-end; */
}
.order_total {
  color: #165fff;
  font-family: fangyuan;
  width: 244px;
  height: 83px;
  background: #e9f3ff;
  /* border: 1px solid #8888885c; */
  padding: 10px;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 0 5px #ccc;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.order_total_top {
  font-size: 16px;
  font-weight: bold;
}
.order_total_bottom {
  display: flex;
  /* margin-top: 30px; */
  justify-content: center;
}
.order_total_left {
  font-size: 60px;
  color: black;
}
.order_total_right {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10px;
}
.column {
  /* margin-top: 40px; */
  display: flex;
  background-color: #f5f7fb;
  font-family: fangyuan;
  color: #1c2028;
  padding: 20px 50px;
  height: 50px;
  line-height: 10px;
  border-radius: 8px 8px 0 0;
  border: 1px solid #ccc;
  justify-content: space-between;
}
.order {
  position: relative;
  border: 1px solid #ccc;
  border-top: none;
  padding-bottom: 40px;
  /* border-radius: 20px; */
  /* margin-top: 20px; */
  /* box-shadow: 0 0 5px 0 #ccc; */
  min-height: 440px;
}
.order_item {
  /* margin-top: 20px; */
  display: flex;
  font-family: fangyuan;
  padding: 20px 50px;
  /* border-radius: 50px; */
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}
.order_item_column {
  min-width: 16%;
  text-align: center;
}
.order_item_column:nth-last-of-type(1) {
  display: flex;
  color: #145dff;
  cursor: pointer;
  justify-content: center;
}
.pagination {
  position: absolute;

  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
}

.feedpagination {
  position: absolute;
  width: 100%;
  /* bottom: 0; */
  right: 0;
  display: flex;
  justify-content: flex-end;
}

.line {
  width: 10px;
  height: 28px;
  margin-right: 6px;
  border-right: solid #1890ff 6px;
  border-radius: 4px;
}

.flex {
  display: flex;
}

.flex-mid {
  align-items: center;
}

.info {
  font-family: "Ma";
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #86929e;
  width: 100%;
}

.txtbox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 55px;
  margin-left: 30px;
}

.infotxt {
  width: 33%;
}

::v-deep .el-tabs__item {
  font-family: "ma";
  font-size: 20px;
}

.bindMobile {
  font-size: "fangyuan";
  color: #145dff;
  cursor: pointer;
}
</style>


