<template>
  <div class="container">
    <div class="content">
      <div class="content_nav">
        <div class="link">
          <div>
            <img
              style="width: 19px; height: 21px"
              src="../../assets/友情链接.png"
            />
          </div>
          <div style="font-family: 'Ma'">友情链接</div>
        </div>

        <!-- <div v-if="avatar">
          <el-popover
            placement="right"
            trigger="hover"
            width="100px"
            v-model="popoverVisible"
          >
            <div class="user_info_index" @click="handleGoUserInfo">
              <div style="margin-left: 10px">个人中心</div>
            </div>
            <template #reference>
              <img :src="avatar" style="width: 30px; height: 30px" />
            </template>
          </el-popover>
        </div>
        <div
          v-else
          style="font-family: 'Ma'; cursor: pointer"
          @click="handleLogin"
        >
          用户中心
        </div> -->
      </div>
      <div class="download">软件下载,从这里开始</div>
      <div class="search">
        <el-input
          v-if="isMobile"
          placeholder="请输入内容"
          v-model="input2"
          class="input-with-select"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleEnterKey"
          ></el-button>
        </el-input>
        <el-input
          v-else
          suffix-icon="el-icon-search"
          v-model="input2"
          style="width: 834px; height: 51px; font-size: 25px"
          @keyup.enter.native="handleEnterKey"
          @blur="handleEnterKey"
        >
        </el-input>
      </div>
      <div v-if="!searchcontentShow">
        <div class="content_box" v-if="isMobile">
          <div>
            <div class="content_box__title">
              <div style="margin-right: 10px">
                <img src="../../assets/火爆.png" alt="" />
              </div>
              <div :id="commonTagsId">常用软件</div>
            </div>
            <div class="content_box__con">
              <div
                v-for="(item, index) in commonTags"
                :key="index"
                class="content_box__con_item"
                @click="handleJump(item.id, item.name)"
              >
                <div>
                  <img
                    :src="item.icon_image"
                    style="width: 30px; height: 30px"
                  />
                </div>
                <div class="content_box__con_item_text">{{ item.name }}</div>
              </div>
            </div>
          </div>
          <div style="width: 100%">
            <div v-for="(item, index) in productList" :key="index">
              <div class="content_box__title" :id="item.id">
                <div style="margin-right: 10px">
                  <img :src="item.icon_image" alt="" />
                </div>
                <div>{{ item.name }}</div>
              </div>
              <div class="software">
                <div
                  v-for="(ite, idx) in item.children"
                  :key="idx"
                  class="software_item"
                  @click="handleJump(ite.id, ite.name)"
                >
                  <div class="software_item_top">
                    <div>
                      <img
                        :src="ite.icon_image"
                        style="width: 20px; height: 20px"
                      />
                    </div>
                    <div class="software_item_top_text">
                      {{ ite.name }}
                    </div>
                  </div>
                  <div class="software_item_bottom">
                    {{ ite.intro }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content_box" v-else>
          <div>
            <div class="content_box__title">
              <div style="margin-right: 10px">
                <img src="../../assets/火爆.png" alt="" />
              </div>
              <div :id="commonTagsId">常用软件</div>
            </div>
            <div class="content_box__con">
              <div
                v-for="(item, index) in commonTags"
                :key="index"
                class="content_box__con_item"
                @click="handleJump(item.id, item.id)"
              >
                <div>
                  <img
                    :src="item.icon_image"
                    style="width: 70px; height: 70px"
                  />
                </div>
                <div class="content_box__con_item_text">{{ item.name }}</div>
              </div>
            </div>
          </div>
          <div>
            <div v-for="(item, index) in productList" :key="index">
              <div class="content_box__title" :id="item.id">
                <div style="margin-right: 10px">
                  <img :src="item.icon_image" alt="" />
                </div>
                <div>{{ item.name }}</div>
              </div>
              <div class="software">
                <div
                  v-for="(ite, idx) in item.children"
                  :key="idx"
                  class="software_item"
                  @click="handleJump(ite.id, item.id)"
                >
                  <div class="software_item_top">
                    <div>
                      <img
                        :src="ite.icon_image"
                        style="width: 40px; height: 40px"
                      />
                    </div>
                    <div class="software_item_top_text">
                      {{ ite.name }}
                    </div>
                  </div>
                  <div class="software_item_bottom">
                    {{ ite.intro }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="searchcontentShow"
        style="margin-bottom: calc(100vh - 435px); padding: 20px"
      >
        <div v-if="isMobile">
          <div class="software">
            <div
              v-for="(ite, idx) in searchList"
              :key="idx"
              class="software_item"
              @click="handleJump(ite.id, ite.pid)"
            >
              <div class="software_item_top">
                <div>
                  <img
                    :src="ite.icon_image"
                    style="width: 20px; height: 20px"
                  />
                </div>
                <div class="software_item_top_text">
                  {{ ite.name }}
                </div>
              </div>
              <div class="software_item_bottom">
                {{ ite.intro }}
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="software">
            <div
              v-for="(ite, idx) in searchList"
              :key="idx"
              class="software_item"
              @click="handleJump(ite.id, ite.pid)"
            >
              <div class="software_item_top">
                <div>
                  <img
                    :src="ite.icon_image"
                    style="width: 40px; height: 40px"
                  />
                </div>
                <div class="software_item_top_text">
                  {{ ite.name }}
                </div>
              </div>
              <div class="software_item_bottom">
                {{ ite.intro }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="bottom">Copyright © 2024 软件总管</div> -->
      <foot v-if="productList.length" :isMobile="isMobile" />
    </div>
    <LoginDialog
      v-if="loginDialog"
      @close="handleclose"
      @avatar="handleAvatar"
    ></LoginDialog>
  </div>
</template>

<script>
import {
  getContentListAPI,
  getProductListAPI,
  getUserInfoAPI,
} from "../../api/index.js";
import foot from "@/components/foot.vue";
import textMore from "@/components/textMore.vue";
export default {
  data() {
    return {
      input2: "",
      commonTags: [],
      contentList: [],
      productList: [],
      loginDialog: false,
      login: {},
      timer: "",
      avatar: "",
      commonTagsId: "",
      isMobile: false,
      popoverVisible: false,
      searchcontentShow: false,
      searchList: [],
    };
  },
  metaInfo: function () {
    return {
      title: "软件总管--站式软件搜索、下载、安装平台|安全纯净|专业服务",
      meta: [
        {
          name: "describe",
          content:
            "软件总管，汇聚Win、Mac、Android各类系统软件与插件，提供一站式搜索、下载安装服务。我们承诺所有软件均经专业工程师亲测无毒，去插件、去捆绑，确保纯净安全。极智云盘稳定下载通道，下载无忧。专业工程师在线回复，解决您的所有问题，更专业、更贴心。",
        },
        {
          name: "keyword",
          content:
            "软件总管,cad, ps,office,系统软件,插件, Win,Mac, Android,安全纯净,无毒, 去插件去捆绑, 极智云盘,, 稳定下载, 专业工程师,在线回复,专业服务",
        },
      ],
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      this.avatar = localStorage.getItem("avatar");
    }
    this.getContentList();
    this.getProductList();
    this.checkDevice();
    // this.handleScroll()
  },
  components: {
    foot,
    textMore,
  },
  mounted() {
    if (this.$route.query.token) {
      localStorage.setItem("token", this.$route.query.token);
      this.getUserInfo();
    }
    const scroll = localStorage.getItem("scroll");
    this.$nextTick(() => {
      if (scroll) {
        this.handleScroll(scroll);
      }
    });
  },
  methods: {
    handleScroll(key) {
      console.log(key);

      setTimeout(() => {
        const targetElement = document.getElementById(key);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
          localStorage.removeItem("scroll");
        }
      }, 500);
    },
    handleAvatar(avatar) {
      this.avatar = avatar;
    },
    handleclose(mode) {
      this.loginDialog = mode;
    },
    handleReportDialog() {
      this.reportDialog = true;
    },
    async getUserInfo() {
      const res = await getUserInfoAPI({
        token: this.$route.query.token,
      });

      localStorage.setItem("nickname", res.data.nickname);
      localStorage.setItem("avatar", res.data.avatar);
    },
    // 调起微信授权登录
    wechatLogin() {
      const appId = "wx1128683816734c2a";
      const redirectUri = "https://rjzg.hrfu.top/Api/login/oauth_callback";
      const scope = "snsapi_userinfo"; // 根据需求选择授权 scope，snsapi_userinfo 可获取用户详细信息，snsapi_base 仅获取 openid
      const state = "";
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
      window.location.href = url;
    },
    async getProductList() {
      const res = await getProductListAPI();
      // this.commonTags = res.data[0].children;
      // this.commonTagsId = res.data[0].id;
      this.commonTags = res.data.host;
      this.commonTagsId = res.data.list[0].id;

      this.productList = res.data.list;
      // this.productList.shift();
    },
    async getContentList() {
      const res = await getContentListAPI();
      this.contentList = res.data;
    },
    async handleLogin() {
      if (this.isMobile) {
        this.wechatLogin();
      } else {
        this.loginDialog = true;
      }
    },
    handleJump(id, pid) {
      this.$router.push({
        path: "/product",
        query: {
          id: id,
          pid,
        },
      });
      const activeIndex = pid;
      this.$store.commit("setActive", activeIndex);
    },

    async handleEnterKey() {
      if (this.input2) {
        const res = await getProductListAPI({
          name: this.input2,
        });
        if (res.data.list.length) {
          // this.$router.push({
          //   path: "/product",
          //   query: {
          //     id: res.data[0].id,
          //     name: res.data[0].name,
          //   },
          // });
          this.searchList = res.data.list;
          this.searchcontentShow = true;
        } else {
          this.searchcontentShow = false;
          this.$message.error("没有搜索到内容!");
        }
      } else {
        const res = await getProductListAPI();
        // this.commonTags = res.data[0].children;
        // this.commonTagsId = res.data[0].id;
        this.commonTags = res.data.host;
        this.commonTagsId = res.data.list[0].id;

        this.productList = res.data.list;
        this.searchcontentShow = false;
      }
    },
    checkDevice() {
      const width = window.innerWidth;
      this.isMobile = width <= 768; //
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleGoUserInfo() {
      this.$router.push({
        path: "/userinfo",
      });
    },
  },
};
</script>
<style scoped>
::v-deep .el-input__icon {
  width: 60px !important;
}
.el-popover {
  background-color: #4981ff !important;
  padding: 5px 0 !important;
  padding-left: 10px !important;

  color: #fff !important;
  border-radius: 10px !important;
  font-weight: bold !important;
  box-shadow: 0 0 5px 0 rgba(90, 39, 39, 0.5) !important;
  border: 3px solid #fff !important;
  min-width: 120px !important;
}
.el-popper .popper__arrow::after {
  border-color: transparent #4981ff transparent transparent !important;
}

::v-deep .el-input__inner {
  height: 40px;
}
</style>
<style >
.el-dialog {
  width: 0 !important;
  height: 0 !important;
}
.user_info_index {
  cursor: pointer;
  width: 100px;
}
.content {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow-y: scroll;
}

.container {
  display: flex;
}
.content_nav {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  padding-top: 5px;
  color: #fff;
}
.download {
  font-size: 72px;
  font-family: "Ma";
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 0.278;
  text-shadow: 0px 4px 6px rgba(25, 25, 25, 0.1);
  margin-top: 70px;
  text-align: center;
}
.search {
  margin-top: 70px;
  display: flex;
  justify-content: center;
}

.content_box {
  margin-top: 90px;
  padding: 20px;
}
.content_box__title {
  width: 130px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3f7cff;
  border-radius: 50px;
  color: #fff;
  font-family: fangyuan;
  margin-top: 20px;
}
.content_box__con {
  /* padding: 0 100px; */
  padding: 10px;
  margin-top: 16px;
  /* height: 108px; */
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-wrap: wrap;
}
.content_box__con_item {
  width: 12.5%;
  display: flex;
  margin: 5px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: fangyuan;
  cursor: pointer;
}
.content_box__con_item_text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: bold;
}
.software {
  /* max-height: 203px; */
  width: 100%;
  /* padding-top: 10px; */
  display: flex;
  flex-wrap: wrap;
  /* justify-content: flex-start; */
  /* align-items: top; */
}
.software_item {
  width: 213px;
  font-family: fangyuan;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 10px 20px;
  position: relative;
  transition: all 0.2s ease;
  /* height: 80px; */
  margin-right: 18px;
  margin-top: 18px;
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid transparent;
}

.software_item:hover {
  transform: translateY(-5px);
  border: 2px solid #00abff;
}
.software_item_top {
  display: flex;
  align-items: center;
  font-weight: bold;
}
.software_item_bottom {
  font-size: 12px;
  color: #898989;
  font-weight: bold;
}
.software_item_top_text {
  margin-left: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bottom {
  font-family: fangyuan;
  font-size: 14px;
  color: #fff;
}
.el-submenu {
  font-family: fangyuan;
}
.el-menu-item {
  color: #9fa7af !important;
}
.el-submenu__title {
  color: #9fa7af !important;
}

.login {
  position: relative;
  top: 0;
  left: -473px;
}
.link {
  display: flex;
}
@media (max-width: 768px) {
  div {
    box-sizing: border-box;
  }
  .container {
    width: 100%;
    box-sizing: border-box;
  }
  .content {
    box-sizing: border-box;
  }
  .content_box {
    width: 100%;
  }
  .link {
    display: none;
  }
  .download {
    width: 100%;
    font-size: 30px;
  }
  .search {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
  }
  .content_box__con {
    box-sizing: border-box;
    padding: 20px;
  }
  .content_box__con_item {
    width: 25%;
  }
  .software_item_bottom {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .software_item {
    box-sizing: border-box;
    overflow: hidden;
    padding: 8px;
    margin-right: 0;
    width: 43%;
    font-size: 16px;
    margin: 0 3%;
    margin-top: 20px;
  }
  .software {
    box-sizing: border-box;
    max-height: none;
    justify-content: flex-start;
  }
  .content_box {
    padding: 0 5px;
  }
  .software {
    width: 100%;
    padding: 0 20px;
  }
  .content_nav {
    justify-content: flex-end;
  }
  .software_item_top {
    box-sizing: border-box;
  }
  .software_item_bottom {
    box-sizing: border-box;
  }
}
.footer {
  position: fixed;
  right: 0;
  top: 50%;
  width: 70px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 2px 0 #ccc;
}
.footer__item {
  box-sizing: border-box;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  padding: 5px;
  color: #9fa7af;
  cursor: pointer;
}
.footer__item:hover {
  background-color: #4981ff;
  color: #fff;
}
.footer__item:first-of-type {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.footer__item:last-of-type {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.report {
  color: blue;
  cursor: pointer;
}
</style>
