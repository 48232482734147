import axios from 'axios'
import { Message } from 'element-ui'
const request = axios.create({
  baseURL: 'https://www.softzg.cn',
  timeout: 5000
})
// 请求拦截器
request.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token && config.noAuth) {
      config.headers.Token = token
    }
    config.headers.Sign = 'HC25ikm8CWImzttLSc7oFHIgvcZKZqDE'
    config.headers.token = localStorage.getItem('token')
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
// 响应拦截器
request.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = '请求错误'
          break
        case 403:
          error.message = '拒绝访问'
          break
        case 404:
          error.message = '请求地址出错'
          break
        case 408:
          error.message = '请求超时'
          break
        case 500:
          error.message = '服务器内部错误'
          break
        case 501:
          error.message = '服务未实现'
          break
        case 502:
          error.message = '网关错误'
          break
        case 503:
          error.message = '服务不可用'
          break
        case 504:
          error.message = '网关超时'
          break
        case 505:
          error.message = 'HTTP版本不受支持'
          break
        default:
          break
      }
    }
    Message({
      message: error.message,
      type: 'error'
    })
    return Promise.reject(error)
  }
)
export default request
