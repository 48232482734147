<template>
  <div>
    <el-dialog
      :visible.sync="serverDialog"
      ref="dialog"
      :show-close="false"
      custom-class="serverdialog"
      @close="handleclose"
    >
      <div class="server">
        <img style="width: 480px; height: 480px" src="@/assets/联系客服.png" />
        <div style="position: absolute; top: 255px; left: -53px">
          <img src="@/assets/newkefu.jpg" style="width: 200px; height: 200px" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ServerDialog",
  data() {
    return {
      serverDialog: false,
      login: {},
    };
  },
  created() {
    this.serverDialog = true;
  },
  methods: {
    handleclose() {
      this.serverDialog = false;
      this.$emit("close", false);
    },
  },
};
</script>
<style >
.serverdialog > .el-dialog {
  width: 0px !important;
  height: 0px !important;
}
</style>
<style scoped>
.server {
  margin-left: -214px;
}
</style>

