import Vue from 'vue'
import Vuex from 'vuex'
import { getProductListAPI, getProductdetailsAPI } from '@/api/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    id: '',
    productList: [],
    activeMenuItem: '',
    details: {}
  },
  getters: {
  },
  mutations: {
    getProductList (state, data) {
      state.productList = data
    },
    setActive (state, data) {
      state.activeMenuItem = String(data)
    },
    setId (state, data) {
      state.id = data
    },
    setDetails (state, data) {

    }
  },
  actions: {
    async getProductList (context) {
      const res = await getProductListAPI()
      context.commit('getProductList', res.data)
    },
    async getProductdetails (context, id) {
      const res = await getProductdetailsAPI({
        id
      })
      context.commit('setDetails', res.data)
    }
  },
  modules: {
  }
})
