<template>
  <div>
    <div class="sidebar" :class="[isShow ? 'sidebar_move' : '']">
      <div class="sidebar_top">
        <!-- <el-popover
          placement="right"
          trigger="hover"
          width="100px"
          v-model="popoverVisible"
        >
          <div class="goback" @click="handleGoBack">
            <img
              src="../../assets/home.png"
              style="width: 22px; height: 22px"
            />
            <div style="margin-left: 10px">回到首页</div>
          </div>
          <template #reference>
            <img
              src="../../assets/椭圆 1.png"
              style="width: 73px; height: 73px"
            />
          </template>
        </el-popover> -->
        <img
          src="../../assets/logo.png"
          @click="handleGoBack"
          style="width: 36px; height: 36px; cursor: pointer"
        />
        <div class="sidebar_top_title">软件总管</div>

        <el-button
          type="primary"
          v-if="token"
          @click="goOrder"
          style="width: 150px; margin-top: 10px"
          plain
          >订单记录</el-button
        >

        <el-input
          placeholder="请输入内容"
          @keyup.enter.native="searchMenu"
          style="width: 150px; margin-top: 10px"
          v-model="searchValue"
          @blur="searchMenu"
        >
          <i
            slot="prefix"
            @click="searchMenu"
            class="el-input__icon el-icon-search"
          ></i>
        </el-input>
      </div>
      <div class="sidebarleft">
        <el-menu
          class="el-menu-vertical-demo"
          :collapse="false"
          text-color="#9FA7AF;"
          style="margin-bottom: 100px"
          :unique-opened="true"
          v-if="!onceMenu"
          @open="handleOpen"
        >
          <el-submenu
            v-for="(item, index) in productList"
            :index="index + ''"
            :collapse="false"
            :key="item.id"
          >
            <template slot="title">
              <img :src="item.icon_image" style="width: 30px; height: 30px" />
              <span>{{ item.name }}</span>
            </template>
            <el-menu-item
              :index="item.id + '-' + idx"
              v-for="(ite, idx) in item.children"
              :key="ite.id"
              @click="handleCLickSon(ite.id, ite)"
            >
              <template slot="title">
                <img :src="item.icon_image" style="width: 25px; height: 25px" />
                <span>{{ ite.name }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>

        <div v-else>
          <div
            v-for="item in productList"
            :key="item.id"
            style="display: flex"
            class="oneMenu"
            @click="handleCLickSon(item.id, item.name)"
          >
            <img :src="item.icon_image" style="width: 25px; height: 25px" />
            <span style="margin-left: 10px">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="sidebarright" @click="handelClose"></div>
      <div></div>
      <div class="about">
        <el-dropdown v-if="token">
          <span class="el-dropdown-link">
            <div style="display: flex; align-items: center">
              <img
                style="width: 25px; height: 25px; margin-right: 15px"
                :src="avatar"
                alt=""
              />
              <span class="register">{{ nickname }}</span
              ><i class="el-icon-arrow-right el-icon--right"></i>
            </div>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="handleGoUserInfo"
              >个人中心</el-dropdown-item
            >
            <el-dropdown-item @click.native="handleGoUserInfo"
              >订单记录</el-dropdown-item
            >
            <el-dropdown-item @click.native="handleGoUserInfo"
              >我的反馈</el-dropdown-item
            >
            <el-dropdown-item @click.native="loginOut"
              >退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <span v-else class="register" @click="handleLogin">注册/登录</span>
        <!-- <i class="el-icon-user"></i>
        关于我们 -->
      </div>
    </div>
    <div class="drover" @click="handleOpenDrawer">
      <i class="el-icon-s-unfold"></i>
    </div>

    <LoginDialog
      v-if="loginDialog"
      @close="handleclose"
      @getUsrInfo="getUsrInfo"
    ></LoginDialog>
  </div>
</template>

<script>
import { getProductListAPI } from "@/api/index.js";

export default {
  name: "Sidbar",
  data() {
    return {
      avatar: "",
      token: "",
      nickname: "",
      productList: [],
      popoverVisible: false,
      activeMenuItem: "",
      activeMenuItemArray: [],
      isShow: false,
      isCollapsed: false,
      searchValue: "",
      loginDialog: false,
      onceMenu: false,
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.nickname = localStorage.getItem("nickname");
    this.avatar = localStorage.getItem("avatar");
    console.log("avatar", this.avatar);
    this.getProductList();
    //brother.vue

    this.$bus.$on("getToken", (res) => {
      console.log("+++++++++++++++", res); // res就是sister.vue里choseEvent方法传递的值data
      if (res) {
        this.token = localStorage.getItem("token");
        this.nickname = localStorage.getItem("nickname");
        this.avatar = localStorage.getItem("avatar");
      }
    });
  },
  beforeDestroy() {
    this.$bus.$off("getToken");
  },
  watch: {
    $route(to, from) {
      if (to.path.startsWith("/product") && from.fullPath === "/") {
        this.activeMenuItemArray = [];
        console.log(this.$store.state.activeMenuItem);
        this.activeMenuItemArray.push(this.$store.state.activeMenuItem);
      } else if (to.path.startsWith("/")) {
        this.activeMenuItemArray = [];
      }
    },
  },
  methods: {
    goOrder() {
      if (this.$route.path !== "/userinfo") {
        this.$router.push({
          path: "/userinfo",
        });
      }
    },
    loginOut() {
      window.localStorage.clear();
      console.log(this.$route);
      if (this.$route.path != "/") {
        this.$router.push("/");
      }
      // this.$router.push("/");
      this.getUsrInfo();
    },
    getUsrInfo() {
      this.token = localStorage.getItem("token");
      this.nickname = localStorage.getItem("nickname");
      this.avatar = localStorage.getItem("avatar");
    },
    handleGoUserInfo() {
      if (this.$route.path !== "/userinfo") {
        this.$router.push({
          path: "/userinfo",
        });
      }
      // this.token = localStorage.getItem("token");
      // if (this.token) {
      //   this.$router.push({
      //     path: "/userinfo",
      //   });
      // } else {
      //   this.loginDialog = true;
      // }
    },
    handleLogin() {
      this.loginDialog = true;
    },
    // handleAvatar(avatar) {
    //   this.avatar = avatar;
    // },
    handleclose(mode) {
      this.loginDialog = mode;
    },
    searchMenu() {
      this.getProductList();
    },
    handleCLickSon(id, ite) {
      console.log(id);
      console.log(this.$route.query.id);
      console.log(ite);

      if (this.$route.query.id) {
        if (String(id) !== this.$route.query.id) {
          if (this.$route.path === "/product") {
            this.$router.push({
              path: "/product",
              query: {
                id: id,
              },
            });
          }
        }
      } else {
        this.$router.push({
          path: "/product",
          query: {
            id: id,
          },
        });
      }
    },
    async getProductList() {
      if (this.searchValue) {
        const res = await getProductListAPI({ name: this.searchValue });
        this.productList = res.data.list;
        this.productList.forEach((item, index) => {
          if (item.name == "常用软件") {
            this.productList.splice(index, 1);
          }
        });
      } else {
        const res = await getProductListAPI();
        this.productList = res.data.list;
        this.productList.forEach((item, index) => {
          if (item.name == "常用软件") {
            this.productList.splice(index, 1);
          }
        });
      }

      for (const item of this.productList) {
        if (!item.children) {
          this.onceMenu = true;
        } else {
          this.onceMenu = false;
        }
      }
      console.log("=====", this.productList);
    },
    handleGoBack() {
      if (this.$route.path !== "/") {
        this.$router.push({
          path: "/",
        });
      }
      this.searchValue = "";
      this.searchMenu();
    },
    handleOpen(key) {
      if (this.$route.path === "/") {
        const targetElement = document.getElementById(key);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      } else if (this.$route.path === "/userinfo") {
        this.$router.push({
          path: "/",
        });
        localStorage.setItem("scroll", key);
      }
      this.isShow = false;
    },
    handleOpenDrawer() {
      this.isShow = !this.isShow;
    },
    handelClose() {
      this.isShow = false;
    },
  },
};
</script>

<style scoped>
.sidebar {
  width: 200px;
  height: 100vh;
  position: fixed;
  left: 0;
  padding-bottom: 30px;
  overflow-y: scroll;
}
.sidebarleft {
  width: 200px;
  /* height: 100vh; */
  height: calc(100vh - 200px);
  position: fixed;
  left: 0;
  padding-bottom: 30px;
  overflow-y: scroll;
}
.sidebarleft::-webkit-scrollbar {
  display: none; /* 隐藏滚动条 */
}
.sidebar::-webkit-scrollbar {
  display: none; /* 隐藏滚动条 */
}
.sidebar_top {
  width: 100%;
  height: 200px;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sidebar_top_title {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}
.product {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product_title {
  display: flex;
  align-items: center;
  color: #9fa7af;
}

.goback {
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  font-family: fangyuan;
  display: flex;
  align-items: center;
}
.about {
  position: fixed;
  bottom: 0;
  color: #a4acb3;
  font-size: 14px;
  font-family: fangyuan;
  text-align: center;
  width: 200px;
  padding: 10px 0;
  background-color: #fff;
}
.drover {
  display: none;
}

::v-deep .el-input__inner {
  box-shadow: 0 0 0 1px #e6a23c !important;
  border: none;
  height: 35px;
}

::v-deep .el-input__icon {
  color: #e6a23c !important;
}

.register {
  cursor: pointer;
}

::v-deep .el-button {
  height: 35px;
  line-height: 8px;
}

.oneMenu {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  color: #9fa7af;
  font-size: 14px;
  font-family: "fangyuan";
  cursor: pointer;
}

.oneMenu:hover {
  background: #ecf5ff;
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
  .about {
    display: none;
  }
  .sidebar_top {
    display: none;
  }
  .drover {
    display: block;
    position: fixed;
    top: 20px;
    left: 10px;
    font-size: 35px;
    color: #fff;
    font-family: fangyuan;
  }
  .sidebar_move {
    display: block;
    height: 100vh;
    z-index: 9999;
    width: 100%;
  }
  .sidebarleft {
    height: 100%;
    overflow-y: scroll;
    background-color: #fff;
  }
  .sidebarright {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
  }
}
</style>
