import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4e5f7b4a&scoped=true"
var script = {}
import style0 from "./index.vue?vue&type=style&index=0&id=4e5f7b4a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e5f7b4a",
  null
  
)

export default component.exports