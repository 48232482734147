import request from '@/utils/request'

export const getProductListAPI = (params) => {
  return request({
    url: '/Api/Product/list',
    method: 'get',
    params
  })
}
export const getContentListAPI = (params) => {
  return request({
    url: '/Api/Product/product_lists',
    method: 'get',
    params
  })
}
export const getCommonTagsAPI = (params) => {
  return request({
    url: '/Api/Product/getCommonTags',
    method: 'get',
    params
  })
}
export const getQrcodeAPI = (params) => {
  return request({
    url: '/weixin/login/getOaScanCode',
    method: 'get',
    params
  })
}
export const trainingInRotation = (params) => {
  return request({
    url: '/weixin/login/oaScanLogin',
    method: 'get',
    params
  })
}
export const getProductInfoAPI = (params) => {
  return request({
    url: '/Api/Product/products_id',
    method: 'get',
    params
  })
}
export const getProductdetailsAPI = (params) => {
  return request({
    url: '/Api/Product/product_details',
    method: 'get',
    params
  })
}
export const getUserInfoAPI = (params) => {
  return request({
    url: '/Api/Login/UserInfo',
    method: 'get',
    params
  })
}
export const feedback = (data) => {
  return request({
    url: '/Api/Login/feedback_add',
    method: 'post',
    data
  })
}
export const getOrderComboAPI = (params) => {
  return request({
    url: '/Api/Product/order_combo',
    method: 'get',
    params
  })
}
export const orderAddAPI = (data) => {
  return request({
    url: '/Api/Login/orderAdd',
    method: 'post',
    data
  })
}
export const getOrderStatusAPI = (params) => {
  return request({
    url: '/Api/Login/order_Status',
    method: 'get',
    params
  })
}
export const getOrderListAPI = (params) => {
  return request({
    url: '/Api/Login/orderInfo',
    method: 'get',
    params
  })
}

export const mobile = (data) => {
  return request({
    url: '/Api/Login/mobile',
    method: 'post',
    data
  })
}

export const feedbackList = (params) => {
  return request({
    url: '/Api/Login/feedback_list',
    method: 'get',
    params
  })
}

export const editUseinfo = (data) => {
  return request({
    url: '/Api/Login/edit_useinfo',
    method: 'post',
    data
  })
}