<template>
  <div :class="{ 'overflow-hidden': !isShowMore }">
    <slot
      >这是一段文字,文字的效果是这样的，超出部分默认会被隐藏，如果点击展开会显示全部内容</slot
    >
    <span v-if="isShowMore" class="link" @click="handleHideMoreClick">
      收起</span
    >
    <span v-else class="show-more-btn" @click="handleShowMoreClick">
      <span> ...</span>
      <span class="link"> 展开</span>
    </span>
  </div>
</template>
<script>
// created at 2021-09-23
export default {
  name: "TextOverflow",
  data() {
    return {
      isShowMore: false,
    };
  },
  methods: {
    handleShowMoreClick() {
      this.isShowMore = true;
    },
    handleHideMoreClick() {
      this.isShowMore = false;
    },
  },
};
</script>
<style>
.overflow-hidden {
  position: relative;
  overflow: hidden;
}
.show-more-btn {
  position: absolute;
  display: block;
  right: 0;
  bottom: 0;
  width: 500px;
  text-align: right;
  /* 背景从透明到白色，过渡 */
  background-image: linear-gradient(to right, transparent, #ffffff 0%);
}
.link {
  color: #409eff;
  cursor: pointer;
}
</style>